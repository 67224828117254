import TrendGoogleRevenueDescription from "../images/trend-google-rev-description.jpg";
import TrendGoogleRevenueDescriptionCst from "../images/trend-google-rev-description-cst.png";

export const MetricDescription = {
  // Inventory Section
  TOTAL_UNITS: {
    title: "Total units",
    desc: "Number of ad units that are currently sending requests to your GAM.",
  },
  SUPPORTED_UNITS: {
    title: "Supported units",
    desc: "Number of ad units whose request types are supported with Intowow’s optimization including Google Publisher Tag, AMP Ad Tag, and Mobile Ads SDK.",
  },
  COMPATIBLE_UNITS: {
    title: "Compatible units",
    desc: "Number of ad units whose request types are mainly Google Publisher Tag and AMP Ad Tag that are compatible with Intowow.",
  },
  ONBOARDED_UNITS: {
    title: "Onboarded units",
    desc: "Number of ad units that are onboarded to the Intowow Platform.",
  },
  RUNNING_UNITS: {
    title: "Running units",
    desc: "Number of ad units that are activated for Intowow optimization.",
  },
  PROTECTED_UNITS: {
    title: "Protected units",
    desc: "Number of ad units that are activated for Intowow protection.",
  },

  TRAFFIC_RUNNING_SCORE: {
    title: "Traffic running score",
    desc: "This score represents the percentage of ad requests activated for Intowow optimization out of the total supported ad requests.",
    desc2: "Calculated as: (running requests / supported requests) * 100.",
  },
  TRAFFIC_PROTECTED_SCORE: {
    title: "Traffic protected score",
    desc: "Score that represents how many of the compatible requests are protected by Intowow against potential bid shading activities.",
    desc2: "Calculated as: (protected requests / compatible requests) * 100.",
  },

  // Algo Section
  CURRENT_SEGMENTS: {
    title: "Current segments",
    desc: "Number of Intowow segments that your running units are currently clustered into.",
    desc2:
      "The higher this number, the more finite traffic granularity it is required to counteract ongoing demand bid shading activities.",
  },
  FLOOR_STRATEGY_SIMULATIONS: {
    title: "Floor strategy simulations",
    desc: "Accumulated number of floor pricing strategy simulations that Intowow has computed since day one. Each simulation produces the optimal floor pricing strategy to protect against buy-side bid shading activities for a given segment at a specific moment.",
  },
  PRICING_DETECTIONS: {
    title: "Pricing detections",
    desc: "Accumulated number of floor pricing detections that Intowow has operated since day one. Pricing detections produce important data points for Intowow to simulate buyer behaviors at any specific moment.",
    desc2:
      "With such detection, Intowow is able to dynamically calculate the most suited traffic segmentation strategy for your inventory, and for each segment the optimal floor pricing strategy at any specific moment.",
  },

  // Summary Section
  NET_INCREASED_GOOGLE_REV: {
    title: "Net increased revenue",
    desc: "Total of increased ad revenue that's net of extra ad serving cost generated by Intowow.",
  },
  PERCEIVED_REVENUE_UPLIFT: {
    title: "Perceived uplift",
    desc: "The percentage increase in revenue from onboarded units contributed by Intowow optimization.",
    desc2: "Calculated as: (net increased revenue / original revenue).",
  },
  OPTIMIZED_LIFT: {
    title: "Optimized Lift",
    desc: "The percentage increase in request RPM from optimized requests contributed by Intowow’s active optimization against ongoing bid shading activities. ",
    desc2:
      "Calculated as: [(optimized request RPM - benchmark request RPM) / benchmark request RPM]",
  },
  SECURED_LIFT: {
    title: "Secured Lift",
    desc: "The percentage increase in request RPM from defended requests contributed by Intowow active defense against ongoing bid shading activities.",
    desc2:
      "Calculated as: [(secured request RPM - benchmark request RPM) / benchmark request RPM]",
  },

  SUPPORTED_REQ: {
    title: "Supported requests",
    desc: "Number of ad requests whose request types are supported with Intowow’s optimization including Google Publisher Tag, AMP Ad Tag, and Mobile Ads SDK.",
  },
  AB_TESTING_TAGGED_REQ: {
    title: "A/B testing tagged requests",
    desc: "Number of ad requests that are tagged with the A/B Testing key-value. (e.g. intowow_optimized = true/ false)",
  },
  OPTIMIZED_REQ: {
    title: "Optimized requests",
    desc: "Number of ad requests that are tagged with the Optimized value. (e.g. intowow_optimized = true)",
  },
  COMPATIBLE_REQ: {
    title: "Compatible requests",
    desc: "Number of ad requests that are compatible with Intowow.",
  },
  PROTECTED_REQ: {
    title: "Protected requests",
    desc: "Number of ad requests that are protected by Intowow against potential bid shading activities. ",
  },
  DEFENDED_REQ: {
    title: "Defended requests",
    desc: "Number of requests actively defended by Intowow against ongoing bid shading activities.",
  },

  AB_TESTING_TAGGED_RATE: {
    title: "A/B testing tagged ratio",
    desc: "The percentage of requests that are tagged with the A/B Testing key-value.",
    desc2: "Calculated as: (A/B Testing Tagged requests / supported requests) ",
  },
  OPTIMIZED_RATE: {
    title: "Optimized ratio",
    desc: "The percentage of requests that are tagged with the Optimized key-value.",
    desc2: "Calculated as: (Optimized requests / A/B Testing Tagged Requests) ",
  },
  PROTECTED_RATE: {
    title: "Protected rate",
    desc: "The percentage of compatible requests that are protected by Intowow.",
    desc2: "Calculated as: (protected requests / compatible requests) ",
  },
  DEFENDED_RATE: {
    title: "Defended rate",
    desc: "The percentage of protected requests that are actively defended by Intowow against ongoing bid shading activities.",
    desc2: "Calculated as: (defended requests / protected requests)",
  },

  YIELD_SET: {
    title: "Yield Set",
    desc: "Yield set can be used as a customized labeling feature to group your ad units. For example, if you have ad units spanning across multiple websites, you can group them by websites, with each yield set containing all ad units under the same website. ",
    desc2:
      "This makes tracking Intowow performance of your inventory easier on the dashboard.",
    desc3: "Contact your Client Success Manager for further support.",
  },

  // Trend Section
  TREND_GOOGLE_REVENUE: {
    title: "Revenue",
    img: TrendGoogleRevenueDescription,
    metrics: [
      {
        title: "Increased Revenue",
        desc: "Number of revenue that Intowow has helped you secure from defending against ongoing bid shading activities, on top of the base revenue.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Original Revenue",
        desc: "Number of revenue that you would have originally earned from requests protected by Intowow.",
        // desc2:
        //   "Calculated as: (unmanaged revenue + base revenue)",
        symbolShape: "square", // square/circle/line
        symbolColor: "#2F5BB0", // dark blue
      },
      {
        title: "Not-Protected Revenue",
        desc: "Number of revenue generated from requests that are not protected by Intowow.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C6DBFF", // light blue
      },
      {
        title: "Extra Ad Serving Cost",
        desc: "Number of GAM ad serving cost that Intowow has generated additionally during optimization.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C5C5C5", // light gray
      },
      {
        title: "Total Requests",
        desc: "Number of ad requests that are compatible with Intowow.",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3B3B3F", // black
      },
    ],
  },
  TREND_GOOGLE_REVENUE_CST: {
    title: "Revenue",
    img: TrendGoogleRevenueDescriptionCst,
    metrics: [
      {
        title: "Increased Revenue",
        desc: "Revenue that Intowow has helped you optimized against ongoing bid shading activities, on top of the original revenue.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Base Revenue",
        desc: "Revenue that you would have originally earned from requests optimized by Intowow.",
        // desc2:
        //   "Calculated as: (unmanaged revenue + base revenue)",
        symbolShape: "square", // square/circle/line
        symbolColor: "#2F5BB0", // dark blue
      },
      {
        title: "Benchmark Revenue",
        desc: "Revenue earned from the requests assigned to the benchmark group.",
        // desc2:
        //   "Calculated as: (unmanaged revenue + base revenue)",
        symbolShape: "square", // square/circle/line
        symbolColor: "#7dabf1", // lessdark blue
      },
      {
        title: "Unmanaged Revenue",
        desc: "Revenue generated from requests that were not tagged with a/b testing or not onboarded to Intowow’s Platform.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C6DBFF", // light blue
      },
      {
        title: "Extra Ad Serving Cost",
        desc: "Additional GAM ad serving cost generated during Intowow’s optimization.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C5C5C5", // light gray
      },
      {
        title: "Total Requests",
        desc: "Ad requests that are supported with Intowow’s optimization including Google Publisher Tag, AMP Ad Tag, and Mobile Ads SDK.",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3B3B3F", // black
      },
    ],
  },
  TREND_REQUEST_PROTECTION_INDEX: {
    title: "Request Protection Index",
    metrics: [
      {
        title: "Protected Score",
        desc: "Score that represents how many of the compatible requests are protected by Intowow against potential bid shading activities.",
        desc2:
          "Calculated as: (protected requests / compatible requests) * 100.",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3B3B3F", // black
      },
      {
        title: "Defended Score",
        desc: "Score that represents how many of the protected requests require active defense against ongoing bid shading activities.",
        desc2:
          "Higher score represents more vibrant buy-side bid shading activities toward your inventory.",
        symbolShape: "line", // square/circle/line
        symbolColor: "#345aef", // darkblue
      },
    ],
  },
  TREND_REQUEST_PROTECTION_INDEX_CST: {
    title: "Request Ratio",
    metrics: [
      {
        title: "A/B Testing Tagged Ratio",
        desc: "The percentage of requests that are tagged with the A/B Testing key-value.",
        desc2:
          "Calculated as: (A/B Testing Tagged requests / supported requests)",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3B3B3F", // black
      },
      {
        title: "Optimized Ratio",
        desc: "The percentage of requests that are tagged with the Optimized key-value.",
        desc2:
          "Calculated as: (Optimized requests / A/B Testing Tagged Requests)",
        symbolShape: "line", // square/circle/line
        // symbolColor: "#345aef", // darkblue
        symbolColor: "#FD9840", // orange
      },
    ],
  },
  TREND_REQUEST_FUNNEL: {
    title: "Request Funnel",
    metrics: [
      {
        title: "Compatible Requests",
        desc: "Number of ad requests that are compatible with Intowow.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C6DBFF", // light blue
      },
      {
        title: "Protected Requests",
        desc: "Number of ad requests that are protected by Intowow against potential bid shading activities.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#3C8FFC", // dark blue
      },
      {
        title: "Defended Requests",
        desc: "Number of requests actively defended by Intowow against ongoing bid shading activities.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
    ],
  },
  TREND_REQUEST_FUNNEL_CST: {
    title: "Request Funnel",
    metrics: [
      {
        title: "Supported Requests",
        desc: "Number of ad requests that are supported with Intowow’s optimization including Google Publisher Tag, AMP Ad Tag, and Mobile Ads SDK.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C6DBFF", // light blue
      },
      {
        title: "A/B Testing Tagged Requests",
        desc: "Number of ad requests that are tagged with the A/B Testing key-value. (e.g. intowow_optimized = true/ false)",
        symbolShape: "square", // square/circle/line
        symbolColor: "#3C8FFC", // dark blue
      },
      {
        title: "Optimized Requests",
        desc: "Number of ad requests that are tagged with the Optimized value. (e.g. intowow_optimized = true)",
        symbolShape: "square", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
    ],
  },
  TREND_REVENUE_LIFT: {
    title: "Revenue Lift",
    metrics: [
      {
        title: "Secured Lift",
        desc: "The percentage increase in request RPM from defended requests contributed by Intowow active defense against ongoing bid shading activities.",
        desc2:
          "Calculated as: [(secured request RPM - benchmark request RPM) / benchmark request RPM]",
        symbolShape: "line", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Perceived Lift",
        desc: "The revenue percentage increase from protected requests contributed by Intowow protection.",
        desc2: "Calculated as: (net increased revenue / original revenue).",
        symbolShape: "line", // square/circle/line
        symbolColor: "#E44949", // red
      },
    ],
  },
  TREND_REVENUE_LIFT_CST: {
    title: "Revenue Lift",
    metrics: [
      {
        title: "Optimized Lift",
        desc: "The percentage increase in request RPM from optimized requests contributed by Intowow’s active optimization against ongoing bid shading activities.",
        desc2:
          "Calculated as: [(optimized request RPM - benchmark request RPM) / benchmark request RPM]",
        symbolShape: "line", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
    ],
  },
  TREND_SECURED_RRPM: {
    title: "Secured Request RPM",
    metrics: [
      {
        title: "Secured Request RPM",
        desc: "The ad request RPM from defended requests.",
        desc2: "Calculated as: [(Secured revenue * 1000) / defended requests].",
        symbolShape: "line", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Benchamrk Request RPM",
        desc: "The ad request RPM from a small fraction of defended requests randomly allocated to the benchmark group for reliable simulation of your original traffic earning power in the absence of Intowow protection.",
        desc2:
          "Calculated as: [(benchmark revenue * 1000) / benchmark requests].",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3C8FFC", // dark blue
      },
    ],
  },
  TREND_OPTIMIZED_RRPM: {
    title: "Optimized Request RPM",
    metrics: [
      {
        title: "Optimized Request RPM",
        desc: "The ad request RPM from optimized requests.",
        desc2:
          "Calculated as: [(optimized revenue * 1000) / optimized requests].",
        symbolShape: "line", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Benchamrk Request RPM",
        desc: "The ad request RPM from benchmark requests.",
        desc2:
          "Calculated as: [(benchmark revenue * 1000) / benchmark requests].",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3C8FFC", // dark blue
      },
    ],
  },
};
