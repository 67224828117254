import React from "react";
import { Query } from "react-apollo";

import CommonStyle from "../../../common/styles/common.css";
import YbLoading from "common/components/yb-loading";

import { fetchRespJson } from "../helpers/api-helper";
import HomeDashboardViewV2 from "./home-dashboard-view";
import HomeDashboardView from "../../home-dashboard/states/home-dashboard-view";
import { GET_NETWORK_INFO } from "../../base/query/network-info";
import HomeDashboardViewCST from "../../home-dashboard-cst/states/home-dashboard-view";

class HomeDashboardWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      resultData: null,
      version: null,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const resultData = await fetchRespJson(this.props.reportsUrl);

      if (!resultData) {
        throw new Error("No Data");
      }

      let version = _.get(resultData, "version", "v1");

      const pubId = _.get(resultData, ["publishers", 0, "id"], -1);
      // IMPORTANT HARDCODE FIRST REMOVE LATER
      if (pubId === 523) {
        version = "cst";
      }

      this.setState({
        version,
        resultData,
      });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: "Unable to get home dashboard reports." });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      isLoading,
      errMsg,

      resultData,
      version,
    } = this.state;

    if (isLoading) {
      return (
        <div className={CommonStyle.textAlignCenter}>
          <YbLoading></YbLoading>
        </div>
      );
    }

    if (errMsg) {
      return <div>{errMsg}</div>;
    }

    if (!resultData) return "";

    return (
      <Query query={GET_NETWORK_INFO} fetchPolicy={"network-only"}>
        {({ loading, error, data, refetch }) => {
          if (loading) {
            return (
              <div className={CommonStyle.textAlignCenter}>
                <YbLoading></YbLoading>
              </div>
            );
          }

          if (error) {
            return (
              <div
                style={{
                  textAlign: "center",
                  padding: "56px",
                  minHeight: "100vh",
                }}
              >
                <p>It seems we have no data for this network.</p>
              </div>
            );
          }

          const networks = _(data)
            .get("gamNetworks.edges")
            .map((n) => {
              return n.node;
            });

          if (version === "cst") {
            return (
              <HomeDashboardViewCST
                resultData={resultData}
              ></HomeDashboardViewCST>
            );
          }

          if (version === "v2") {
            return (
              <HomeDashboardViewV2
                resultData={resultData}
              ></HomeDashboardViewV2>
            );
          }

          return (
            <HomeDashboardView resultData={resultData}></HomeDashboardView>
          );
          // const reportsUrl = _.get(data, "homeDashboard.signedUrl");
          // if (data && reportsUrl) {
          //   return (
          //     <HomeDashboardWrapper
          //       reportsUrl={reportsUrl}
          //     ></HomeDashboardWrapper>
          //   );
          // }

          // return (
          //   <div
          //     style={{
          //       textAlign: "center",
          //       padding: "56px",
          //       minHeight: "100vh",
          //     }}
          //   >
          //     Dashboard not available
          //   </div>
          // );
        }}
      </Query>
    );
  }
}

export default HomeDashboardWrapper;
