import React from "react";
import FadeInWrapper from "./fade-in-wrapper";
import MetricTooltip from "./metric-tooltip";
import NumberFormat from "./number-format";
import InfoDescriptionTooltip from "./info-desc-tooltip";
import InventoryScoreGaugeChart from "./inventory-score-gauge-chart";

class InventorySection extends React.PureComponent {
  render() {
    const { inventoryData, selectedYieldSetIds } = this.props;
    const isYieldSetFilter = selectedYieldSetIds !== -1;

    return (
      <React.Fragment>
        <div style={styles.sectionTitle}>Inventory</div>

        <div className="d-flex align-items-stretch">
          <div style={{ width: "75%" }} className="border rounded p-3">
            <div className="d-flex align-items-center justify-content-around">
              <div style={styles.statBlock}>
                <div style={styles.statTitle}>
                  Total Units
                  <InfoDescriptionTooltip
                    metricKey="TOTAL_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div style={styles.statText}>
                  <FadeInWrapper key={inventoryData.totalUnits}>
                    {isYieldSetFilter ? (
                      "-"
                    ) : (
                      <NumberFormat
                        value={inventoryData.totalUnits}
                        format="0,0"
                      ></NumberFormat>
                    )}
                  </FadeInWrapper>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <RightArrow></RightArrow>
              </div>

              <div style={styles.statBlock}>
                <div style={styles.statTitle}>
                  Supported Units
                  <InfoDescriptionTooltip
                    metricKey="SUPPORTED_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div style={styles.statText}>
                  <FadeInWrapper key={inventoryData.compatibleUnits}>
                    {isYieldSetFilter ? (
                      "-"
                    ) : (
                      <MetricTooltip
                        tooltipKey="compatible-unit-tooltip"
                        tooltipMessage={`Compatible Rate: ${inventoryData.compatibleUnitsRate}%`}
                        place="bottom"
                      >
                        <NumberFormat
                          value={inventoryData.compatibleUnits}
                          format="0,0"
                        ></NumberFormat>
                      </MetricTooltip>
                    )}
                  </FadeInWrapper>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <RightArrow></RightArrow>
              </div>

              <div style={styles.statBlock}>
                <div style={styles.statTitle}>
                  Onboarded Units
                  <InfoDescriptionTooltip
                    metricKey="ONBOARDED_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div style={styles.statText}>
                  <FadeInWrapper key={inventoryData.onboardedUnits}>
                    {isYieldSetFilter ? (
                      <NumberFormat
                        value={inventoryData.onboardedUnits}
                        format="0,0"
                      ></NumberFormat>
                    ) : (
                      <MetricTooltip
                        tooltipKey="onboard-unit-tooltip"
                        tooltipMessage={`Onboarded Rate: ${inventoryData.onboardedUnitsRate}%`}
                        place="bottom"
                      >
                        <NumberFormat
                          value={inventoryData.onboardedUnits}
                          format="0,0"
                        ></NumberFormat>
                      </MetricTooltip>
                    )}
                  </FadeInWrapper>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <RightArrow></RightArrow>
              </div>

              <div style={styles.statBlock}>
                <div style={styles.statTitle}>
                  Running Units
                  <InfoDescriptionTooltip
                    metricKey="RUNNING_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div style={styles.statText}>
                  <FadeInWrapper key={inventoryData.runningUnits}>
                    <MetricTooltip
                      tooltipKey="running-unit-tooltip"
                      tooltipMessage={`Protected Rate: ${inventoryData.runningUnitsRate}%`}
                      place="bottom"
                    >
                      <NumberFormat
                        value={inventoryData.runningUnits}
                        format="0,0"
                      ></NumberFormat>
                    </MetricTooltip>
                  </FadeInWrapper>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ width: "25%" }}
            className="bg-light border rounded p-3 ml-3"
          >
            <div style={styles.statBlock}>
              <div style={styles.statTitle}>
                Traffic Running Score
                <InfoDescriptionTooltip
                  metricKey="TRAFFIC_RUNNING_SCORE"
                  place="top"
                ></InfoDescriptionTooltip>
              </div>
              <div style={styles.statText}>
                <FadeInWrapper key={inventoryData.onboardedRequests}>
                  {isYieldSetFilter ? (
                    <FadeInWrapper key={inventoryData.onboardedRequests}>
                      <InventoryScoreGaugeChart
                        value={inventoryData.trafficProtectedScoreYieldSet}
                      ></InventoryScoreGaugeChart>
                    </FadeInWrapper>
                  ) : (
                    <FadeInWrapper key={inventoryData.compatibleRequests}>
                      <InventoryScoreGaugeChart
                        value={inventoryData.trafficProtectedScoreNetwork}
                      ></InventoryScoreGaugeChart>
                    </FadeInWrapper>
                  )}
                </FadeInWrapper>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function RightArrow() {
  return <span className="fa fa-arrow-right" style={styles.arrow}></span>;
}

const styles = {
  sectionTitle: {
    fontSize: "28px",
    marginTop: "16px",
    marginBottom: "16px",
    color: "#374151",
    fontWeight: "600",
  },
  statText: {
    fontSize: "36px",
  },
  arrow: {
    color: "#a6aab2",
  },

  statTitle: {
    color: "#556377",
    fontWeight: "500",
    fontSize: "1rem",
  },
  statBlock: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
};

export default InventorySection;
